import React, { useState } from "react"
import styled from "styled-components"
import Icon from "../../../utils/icon"

const WrapperOne = styled.div.attrs({ className: "singleFilter" })`
position: relative;
text-align: left;
.svgWrapper {
    pointer-events: none;
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    fill:#757575;
`

const WrapperTwo = styled.div`
position: relative;
display:flex;
justify-content: space-between;
padding: 0.5rem;

.title{
    margin:8px 0 0 0;
}
.inner{
    height: 32px;
    div{
        display: inline-block;
        height: 32px;
        width: 44px;
        position: relative;
        top: -10px;
    }

    button{   
        width:32px;
        height:32px;
        fill: ${props => props.theme.colors.primary};
        border:0;
        padding:0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        overflow:hidden;
        &:hover{
            fill: ${props => props.theme.colors.secondary};
        }
        &:disabled{
            opacity:0.2;
            &:hover{
                fill: ${props => props.theme.colors.primary};
            }
        }
    }

}
`


const Select = styled.select.attrs({
    className: "priceRangeSelect",
})`
    grid-area: pricerange;
    ${props => props.theme.formElements.select};
`

const PriceRange = ({ title, value, onClick, concise, range, itemRender }) => {

    //State
    const [valueState] = useState(
        {
            min: range?.min ?? 0,
            increment: range?.increment ?? 200,
            max: range?.max ?? 20,
        }
    )

    function intValue() {
        return value ? value.min : 0;
    }

    function stringValue() {
        return value ? value.min.toString() : "";
    }

    //disable clicks
    function max() {
        return intValue() >= valueState.max;
    }
    function min() {
        return intValue() <= valueState.min;
    }

    //increment
    function IncrementItem() {
        var num = intValue() + valueState.increment;

        sendUp(num);
    }

    //decrement
    function DecreaseItem() {
        var num = intValue() - valueState.increment;

        sendUp(num);
    }

    //send up state
    function handleClick(event) {
        var num = parseInt(event.currentTarget.value);

        sendUp(num);
    }

    function sendUp(val) {
        if (onClick) {
            if (val)
                onClick({ min: val, max: val + valueState.increment });
            else
                onClick(null);
        }
    }

    // function itemTitle(value) {
    //     if (itemRender)
    //         return itemRender(value);
    //     else
    //         return value.toString() + ' ' + titleComposite();
    // }

    function titleComposite() {
        return title;
    }

    // generate the options list
    const options = () => {
        var options = [];
        var i = valueState.min;
        options.push(<option value='' key=''>{`Any ${titleComposite()}`}</option>);

        options.push(<option value={i} key={i}>{`Under ${i + valueState.increment}`}</option>);
        i += valueState.increment;

        for (; i < valueState.max; i += valueState.increment) {
            options.push(<option value={i} key={i}>{`${i}  -  ${i + valueState.increment}`}</option>);
        }

        options.push(<option value={i} key={i}>{`${i} + `}</option>);

        return options;
    }


    if (!concise) {

        return (

            <WrapperOne>

                <Select onChange={handleClick}
                    value={stringValue()}>
                    {options()}
                    {/* <option hidden>Price Range / week</option>
                    <option value="">Any price considered</option>
                    <option value="0">Under £200</option>
                    <option value="200">£200 - £400</option>
                    <option value="400">£400 - £600</option>
                    <option value="600">£600 - £800</option>
                    <option value="800">£800 - £1000</option>
                    <option value="1000">£1000 - £1200</option>
                    <option value="1200">£1200 - £1400</option>
                    <option value="1400">£1400 - £1600</option>
                    <option value="1600">£1600 - £1800</option>
                    <option value="1800">£1800 - £2000</option>
                    <option value="2000">Over £2000</option> */}

                </Select>

                <Icon className="selectArrow" name="selectArrow" height="10px" />

            </WrapperOne>

        )

    } else {

        return (

            <WrapperTwo>

                <div className="title">Price Range</div>
                <div className="inner">
                    <button onClick={DecreaseItem} disabled={min()} aria-label="lower price">
                        <Icon name="minus" height="32px" />
                    </button>
                    <div>{valueState.value}</div>
                    <button onClick={IncrementItem} disabled={max()} aria-label="higher price">
                        <Icon name="plus"  height="32px" />
                    </button>
                </div>

            </WrapperTwo>
        )

    }

}

export default PriceRange