import React from "react"
import styled from "styled-components"
import { gql, useQuery } from "@apollo/client"
import Loader from "../loadingAnim/loader"
import ResultsMap from "./resultsMap"

const Loading = styled.div`
  grid-area: results;

  .inner {
    position: relative;
    z-index: 1;
  }

  .errorMsg {
    text-align: center;
  }
`

// This query is executed at run time by Apollo.
const APOLLO_QUERY = gql`
query getMyProperties($searchquery: SearchQuery) {
  getProperties(query: $searchquery) {
    totalCount
    properties {
      propRef: ref
      name
      wpurl
      rating
      prices {
        priceBase
      }
      description {
        short
      }
      sleeps
      bedrooms
      pets
      where: address {
        town
        coords {
          lat
          lon
        }
      }
      attributes{
        name
        value
      }
      images {
        description
        url
      }
      gallery {
        description
        url
      }
    }
    facets {
      title
      id
      name
      count
    }
  }
}
`

// map stuff
const SearchMap = ({ searchQuery, onLoaded }) => {

    // Our Apollo Client query that does the heavy lifting to fetch our data and returns data to loadDynamic handler
    const { loading, error, data } = useQuery(APOLLO_QUERY, {
        variables: {
        searchquery: {
            ...searchQuery,
            pages: undefined ,
        }
        },
        ssr: false,
        onCompleted: data => {
           // console.log("SearchMap loaded");
           if(onLoaded){
                onLoaded(data?.getProperties);
            }
        }
        
    })

    const propertiesResults = data?.getProperties?.properties;
    
    //=================================
    // render functions

    return (

    <>
        <Loading>
            <div className="inner">
                {loading && 
                    <Loader />
                }
            </div>
            {loading &&
            <div className="errorMsg">
                Searching for your next holiday property
            </div>
            }
            {error && 
                <p>Error: ${error.message}</p>
            }
        </Loading>
        {propertiesResults && 
            <ResultsMap 
                properties={propertiesResults}
            >
            </ResultsMap>
        }
    </>

    )
}

export default SearchMap
