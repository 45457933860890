import React, { useState } from "react"
import styled from "styled-components"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const Wrapper = styled.div`
  display: block;
  vertical-align: middle;
  margin-top: 5px;
`
const CheckboxComponent = ({
  title,
  whatPart,
  onChildClick,
  checked,
  displayCount,
}) => {
  //State
  const [checkedState, setCheckedState] = useState({
    boxName: whatPart,
  })

  //send up state
  function handleClick(event) {
    onChildClick(event)
  }

  
  return (
    <Wrapper>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleClick}
            checked={checked}
            value={checkedState.boxName}
            name={checkedState.boxName}
            id={checkedState.boxName}
            color="primary"
          />}
        label={displayCount ? title + " (" + displayCount + ")" : title}
      />
    </Wrapper>
  )
}

export default CheckboxComponent
