import React from "react"
import styled from "styled-components"
import Map from "../googleMap/mapwrapper"

const MapWrapper = styled.div`
  height:600px;
`

const Properties = styled.div`
  display: grid;
  grid-gap:1rem;

  @media ${props => props.theme.mq.sm} {
    grid-gap:1.5rem;
  }

  @media ${props => props.theme.mq.md} {
    grid-gap:3rem;
  }
  
  &.gridView {
    grid-template-columns: 1fr;

    @media ${props => props.theme.mq.sm} {
      .tileWrapper{
        .slick-slide{
          height:50vh;
        }
      }
    }

    @media ${props => props.theme.mq.lg} {
      grid-template-columns: 1fr 1fr;
      .tileWrapper{
        .slick-slide{
          height:35vh;
        }
      }
    }
    
  }

  &.listView {
    grid-template-columns: 1fr;

    @media ${props => props.theme.mq.sm} {
      .tileWrapper{
        .slick-slide{
          height:50vh;
        }
      }
    }

    @media ${props => props.theme.mq.lg} {
      .tileWrapper{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
          "gallery content";
      }
    }

  }

  &.mapView {
    .tileWrapper{
      max-width:280px;
      box-shadow: none;

      .slick-slide{
        min-height:10rem;
      }

      .tileContent{
        padding:0;
        h4{
          ${props => props.theme.fonts.smallerFontSize};
          margin: 0;
        }
        .location{
          display:none;
        }
      }

    }

  }


`

// map stuff
const ResultsMap = ({ properties, resultsCount }) => {

    const markerList = properties.map((marker, index) => {
        return {
          propRef: marker.propRef,
          latlng: {
            lat: parseFloat(marker.where.coords.lat),
            lng: parseFloat(marker.where.coords.lon)
          },
          info: marker,
          category: "Property",
        }
    });
 
  return (
    <Properties className='mapview'>
        <MapWrapper>
        <Map
            // not totally fallable but key makes sure map reinitialises as the markers list changes 
            // (as map doesnt refresh when the prop changes)
            key ={properties.length}
            apikey={process.env.GATSBY_MAPS_KEY}
            markers={markerList}
            zoom={11}
        />
        </MapWrapper>
    </Properties>

  )
}

export default ResultsMap
