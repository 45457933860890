import React, { useState } from "react"
import styled from "styled-components"
//import Icon from "./icon"
import IconSelectArrow from '../../static/svgs/inline/selectArrow.svg'

// import { makeStyles } from "@material-ui/core/styles";
// import colors from "../theme/colors"
import { Popover } from "@material-ui/core"

// const useStyles = makeStyles({

//     root: {
//         backgroundColor: 'white',
//         borderRadius: '4px',
//     },
//     option: {
//         padding: "0.75rem",
//         '& .innerLi': {
//             display: 'flex',
//             color: colors.primaryFontColor,
//             textDecoration: "none",
//             '& .svgWrapper': {
//                 marginRight: '2rem',
//                 position: "relative",
//                 '& svg': {
//                     position: "absolute",
//                     top: "3px",
//                     fill: colors.primary,
//                 }
//             }
//         }
//     },
  
//   });
  
  // const Wrapper = styled.div`
  //   margin: 0 auto;
  //   .whoPanelBtn {
  //       &.active > .svgWrapper {
  //         height: 22px;
  //         transform: rotate(180deg);
  //       }
  //       ${(props) => props.theme.formElements.select};
  //     }
  // `
  const PopDownControl = styled.div`
  text-align: left;
  svg {
    pointer-events: none;
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    fill:#757575;
  }
  &.pdPanelBtn {
    &.active > svg {
      transform: rotate(180deg);
    }
    ${(props) => props.theme.formElements.select};
  }
`
//
export default function PopDown({ children, inhibit, label, onChange }) {
    
    // const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const panelId = isOpen ? 'simple-popover' : undefined;

    //show panel
    const panelOpen = (event) => {
        setAnchorEl(event.currentTarget);
        console.log(event.currentTarget.scrollWidth);
        if (onChange)
            onChange(true);
    }
  
    const panelClose = () => {
        setAnchorEl(null);
        if (onChange)
        onChange(false);
    };

   if (inhibit) return children;
    
    return (
        <div>
            <PopDownControl
            //   classes={classes}
            onClick={panelOpen}
            className={`pdPanelBtn ${isOpen ? "active" : ""}
            `}
            >
            { label && label }
            <IconSelectArrow className="whoDisplayArrow" height="10px" />
            </PopDownControl>

            <Popover
                id={panelId}
                open={isOpen}
                anchorEl={anchorEl}
                onClose={panelClose}
                PaperProps={{style:{width:anchorEl?.scrollWidth}}}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
            >
                {children}
            </Popover>
        </div>
    );
}