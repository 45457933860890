import React, { useState } from "react"
import styled from "styled-components"
import Icon from "./icon"
import NumericInput from "./numericInput"

const WrapperOne = styled.div.attrs({ className: "singleFilter" })`
position: relative;
text-align: left;
.svgWrapper {
	pointer-events: none;
	position: absolute;
	top: 1.5rem;
	right: 1rem;
 	fill:#757575;
`

const Select = styled.select.attrs({
	className: "numberSelect",
})`
	grid-area: quantity;
	${props => props.theme.formElements.select};
`

const NumberSelect = ({ title, value, onClick, concise, range, itemRender }) => {

	//State
	const [valueState] = useState(
		{
			min: range?.min ?? 0,
			max: range?.max ?? 20,
		}
	)

	function intValue() {
		return value ? value : 0;
	}

	function stringValue() {
		return value ? value.toString() : "";
	}

	//send up state
	function handleClick(event) {
		var num = parseInt(event.currentTarget.value);

		sendUp(num);
	}

	function sendUp(val) {
		if (onClick) {
			onClick(val);
		}
	}

	function itemTitle(value) {
		if (itemRender)
			return itemRender(value);
		else
			return value.toString() + ' ' + titleComposite();
	}

	function titleComposite() {
		return title;
	}

	// generate the options list
	const options = () => {
		var options = [];
		var i;
		options.push(<option value=' ' key=' '>Any {titleComposite()}</option>);
		for (i = valueState.min; i < valueState.max; i++) {
			options.push(<option value={i} key={i}>{itemTitle(i)}</option>);
		}
		return options;
	}

	if (!concise) {
		return (
			<WrapperOne>
				<Select
					onChange={handleClick}
					value={stringValue()}
				>
					{options()}
				</Select>
				<Icon className="selectArrow" name="selectArrow" height="10px" />
			</WrapperOne>
		)
	} else {
		return (
		  <NumericInput 
			title={titleComposite()}
			value = {intValue()}
			onChange ={sendUp}
			range={valueState}
			/>
		)
	}
}

export default NumberSelect