import React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"

import PropertyTile from "../propertyTile/propertyTile"

const Wrapper = styled.div.attrs({
  className: "resultsWrapper",
})`
  grid-area: results;
  padding-bottom: 1.5rem;
`

// const Header = styled.div.attrs({
//   className: "resultsHeader",
// })`
//   margin-bottom: 1rem;
//   display: flex;
//   justify-content: space-between;
// `

const Footer = styled.div.attrs({
  className: "resultsFooter",
})`
margin-top:2rem;
  display: flex;
  justify-content: center;
  button{
    ${props => props.theme.buttons.secondary};
    &.mapView{
      display: none;
    }
  }
`

const Properties = styled.div`
  display: grid;
  grid-gap:1rem;

  @media ${props => props.theme.mq.sm} {
    grid-gap:1.5rem;
  }

  @media ${props => props.theme.mq.md} {
    grid-gap:3rem;
  }
  
  &.gridView {
    grid-template-columns: 1fr;

    @media ${props => props.theme.mq.sm} {
      .tileWrapper{
        .slick-slide{
          height:50vh;
        }
      }
    }

    @media ${props => props.theme.mq.lg} {
      grid-template-columns: 1fr 1fr;
      .tileWrapper{
        .slick-slide{
          height:35vh;
        }
      }
    }
    
  }

  &.listView {
    grid-template-columns: 1fr;

    @media ${props => props.theme.mq.sm} {
      .tileWrapper{
        .slick-slide{
          height:50vh;
        }
      }
    }

    @media ${props => props.theme.mq.lg} {
      .tileWrapper{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
          "gallery content";
      }
    }

  }

  &.mapView {
    .tileWrapper{
      max-width:280px;
      box-shadow: none;

      .slick-slide{
        min-height:10rem;
      }

      .tileContent{
        padding:0;
        h4{
          ${props => props.theme.fonts.smallerFontSize};
          margin: 0;
        }
        .location{
          display:none;
        }
      }

    }

  }


`

// const LayoutToggle = styled.div`
//   ${props => props.theme.formElements.select};
//   width:fit-content;
//   min-width:fit-content;
//   ${props => props.theme.fonts.smallerFontSize};
//   button {
//     background-color: transparent;
//     border: 0;
//     cursor: pointer;
//     border-right: 1px solid ${props => props.theme.colors.lightGrey};

//     &:nth-of-type(2n){
//       display:none;
//       @media ${props => props.theme.mq.lg} {
//         display:inline-block;
//       }
//     }

//     &:last-of-type{
//       border:0;
//     }

//     @media ${props => props.theme.mq.lg} {
//       &:first-of-type{
//         display:inline-block;
//       }
//     }

//   }
//   button.active {
//     color: ${props => props.theme.colors.secondary};
//   }
//`

const ResultsList = ({ properties, onMoreClick, resultsCount, propertiesLayout }) => {

  function loadMore() {
     onMoreClick()
  }

  const allLoaded = properties.length >= resultsCount;

  //in view
  const [ref, inView] = useInView({
    threshold: 0,
  })

  //let { allLoaded, allowInfini } = infiniState

  // //infinite scolling
  // useEffect(() => {
  //   let timer

  //   if (allowInfini && inView && !allLoaded) {
  //     timer = setTimeout(() => {
  //       loadMore()
  //       timer = null
  //     }, 1000)
  //   }
  //   return () => {
  //     if (timer) return clearTimeout(timer)
  //   }
  // }, [allLoaded, allowInfini, inView, properties, resultsState, loadMore])


  // function handleClick(event) {
  //   onChildClick(event)
  // }

  return (
    <Wrapper inView={inView}>

      <Properties className={propertiesLayout}>
        {
          properties.map((properties, index) =>{
            return <PropertyTile props={properties} key={index} />
          })
        }
      </Properties>

      <Footer>
        <button ref={ref} onClick={loadMore} disabled={allLoaded} className={propertiesLayout} >
          {allLoaded ? "No more properties" : "See more properties"}
        </button>

        {/* <button onClick={autoMore}>{allowInfini ? 'Disable infinite scroll' : 'Activate infinite scroll'}</button> */}
      </Footer>
    </Wrapper>
  )
}

export default ResultsList
