import React, { useState } from "react"
import styled from "styled-components"
import Icon from "../../../utils/icon"
import NumericInput from "../../../utils/numericInput"


const WrapperOne = styled.div.attrs({ className: "singleFilter" })`
position: relative;
text-align: left;
.svgWrapper {
  pointer-events: none;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  fill:#757575;
}
`

const Select = styled.select.attrs({
  className: "adultsSelect",
})`
  grid-area: adults;
  ${props => props.theme.formElements.select};
`

const Member = ({ title, value, onClick, concise, range, itemRender, ageRange }) => {

  //State
  const [valueState] = useState(
    {
      //     value: value,
      min: range?.min ?? 0,
      max: range?.max ?? 20,
    }
  )

  function intValue() {
    // return valueState.value?valueState.value:0;
    return value ? value : 0;
  }

  //send up state
  function handleClick(event) {
    var num = parseInt(event.currentTarget.value);

    sendUp(num);
  }

  function sendUp(val) {
    if (onClick) {
      onClick(val);
    }
  }

  function itemTitle(value) {
    if (itemRender)
      return itemRender(value);
    else
      return value.toString() + ' ' + titleComposite();
  }

  function titleComposite() {
    return title + (ageRange ? ' (' + ageRange + ')' : '');
  }

  // generate the options list
  const options = () => {
    var options = [];
    var i;
    for (i = valueState.min; i < valueState.max; i++) {
      options.push(<option value={i} key={i}>{itemTitle(i)}</option>);
    }
    return options;
  }

  if (!concise) {
    return (
      <WrapperOne>
        <Select onChange={handleClick} value={intValue()}>
          {options()}
        </Select>
        <Icon className="selectArrow" name="selectArrow" height="10px" />
      </WrapperOne>
    )
  } else {
    return (
      <NumericInput 
				title={titleComposite()}
				value = {intValue()}
				onChange ={sendUp}
				range={valueState}
				/>
		)
  }
}

export default Member
