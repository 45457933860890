import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import momentPropTypes from "react-moment-proptypes"
import moment from "moment"
import { DateRangePicker } from "react-dates"
import "react-dates/lib/css/_datepicker.css"
import "react-dates/initialize"
//import { truncate } from 'fs';
import styled from "styled-components"
import { WindowDimensionsCtx } from "../../utils/windowDimensionsProvider"
import {
    START_DATE,
    END_DATE,
    // HORIZONTAL_ORIENTATION,
    //VERTICAL_ORIENTATION,
} from "./constants"

const Wrapper = styled.div.attrs({
  className: "drpWrapper",
})`

  .DateRangePickerInput {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: noWrap;
    padding: 0.5rem 30px 0.5rem 0.5rem;
  }

  .DateInput_input {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    background-color: #fff;
    padding: unset;
    border-bottom: 0;
  }

  .DateInput_input__focused {
    border-bottom: 2px solid ${props => props.theme.colors.primary};
  }

  .DateRangePickerInput_arrow_svg {
    fill: inherit;
    width: 16px;
    margin-right: 0.5rem;
    margin-top: -2px;
  }

  .DateRangePickerInput_clearDates_svg {
    fill: ${props => props.theme.colors.darkGrey};
    height: 12px;
    width: 12px;
    vertical-align: inherit;
  }

  .DateRangePickerInput_clearDates_default:hover {
    background: transparent;
    border-radius: 0;
  }


  .DateRangePicker_picker{
    /* top: 50px!important; looks ugly
    enable in full screen mode so datepicker sits on top of other MUI components 
    (only required if append to body is not set)
    zIndex: 1301; */
  }
  .DateInput_fang{
    top: 41px!important;
  }


  /*  Calendar day styling */
  .CalendarDay__default {
    color: ${props => props.theme.colors.primaryFontColor};
    background: #fff;
      &:hover{
        background: rgba(${props => props.theme.colors.secondaryRGB} ,0.5);
      }
  }


  .CalendarDay__highlighted_calendar {
    background: white;
  }

  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: ${props => props.theme.colors.secondary};
    border: 1px double white;
    color: #fff;
  }

  .CalendarDay__selected_span, 
  .CalendarDay__selected_span:active, 
  .CalendarDay__selected_span:hover {
    background: rgba(${props => props.theme.colors.secondaryRGB} ,0.5);
    border: 1px double white;
    color: #fff;
  }

  .CalendarDay__hovered_span, .CalendarDay--hovered-span:hover {
    border: 1px double white;
    background: rgba(${props => props.theme.colors.secondaryRGB} ,0.5);
    color: ${props => props.theme.colors.primaryFontColor};
  }


  .CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
    border: 1px solid #e4e7e7;
    color: #cacccd;
  }


`

function isBeforeDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false
  
    const aYear = a.year()
    const aMonth = a.month()
  
    const bYear = b.year()
    const bMonth = b.month()
  
    const isSameYear = aYear === bYear
    const isSameMonth = aMonth === bMonth
  
    if (isSameYear && isSameMonth) return a.date() < b.date()
    if (isSameYear) return aMonth < bMonth
    return aYear < bYear
  }
  
  function isInclusivelyAfterDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false
    return !isBeforeDay(a, b)
  }
  
  
const FilterDate = props => {
  const [datePicker, setDatePicker] = useState({
    startDate: props.from != null ? moment(props.from) : null,
    endDate: props.to != null ? moment(props.to) : null,
  })

  const [datePickerInput, setDatePickerInput] = useState(null)

  const updateOnFocusChange = focusedInput => {
    setDatePickerInput(focusedInput)
  }

  const monthChange = (e) => {
    // e.stopPropagation();
  }

  const updateOnDatesChange = ({ startDate, endDate }) => {
    const { onChildClick } = { ...props }
    // Both dates are null if clear dates button clicked
    if (startDate === null && endDate === null) {
      const from = null
      const to = null

      onChildClick(from, to)

      setDatePicker({
        ...datePicker,
        startDate: null,
        endDate: null,
      })
    } else {
      // Start or end dates have been set

      const from =
        startDate != null
          ? startDate.toISOString()
          : datePicker.startDate != null
            ? datePicker.startDate.toISOString()
            : null
      const to =
        endDate != null
          ? endDate.toISOString()
          : datePicker.endDate != null
            ? datePicker.endDate.toISOString()
            : null

      onChildClick(from, to)

      setDatePicker({
        ...datePicker,
        startDate: startDate != null ? startDate : datePicker.startDate,
        endDate: endDate != null ? endDate : datePicker.endDate,
      })
    }
  }

  // const blockedDays = [
  //   moment('09/12/19'),
  //   moment('10/12/19'),
  //   moment('11/12/19'),
  // ];

  const mediaContext = useContext(WindowDimensionsCtx)

  const isMobile = () => {
    if (mediaContext.width > 639) {
      return false
    } else {
      return true
    }
  }

  const changeOverDay = day => {
    if (moment(day).day() % 6 === 0) {
      //is weekend
      return true
    } else {
      return false
    }
  }
  // ** For checking between dates MomentJS has the handy isBetween() method - moment(day).isBetween('2019-10-19', '2019-10-25') nice :)
  function checkDay(day) {
    if (moment(day).isBetween("2020-03-22", "2020-03-28")) {
      return true
    } else {
      return false
    }
  }

  return (
    <Wrapper>
      <DateRangePicker
        startDate={datePicker.startDate} // momentPropTypes.momentObj or null,
        startDateId="startDateId" // PropTypes.string.isRequired,
        endDate={datePicker.endDate} // momentPropTypes.momentObj or null,
        endDateId="endDateId" // PropTypes.string.isRequired,
        endDatePlaceholderText="Departure"
        onDatesChange={({ startDate, endDate }) =>
          updateOnDatesChange({ startDate, endDate })
        } // PropTypes.func.isRequired,
        focusedInput={datePickerInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={focusedInput => updateOnFocusChange(focusedInput)} // PropTypes.func.isRequired,
        onPrevMonthClick={monthChange()}
        displayFormat="DD MMM YYYY"
        monthFormat={props.monthFormat}
        numberOfMonths={isMobile() ? 1 : 2}
        isDayHighlighted={changeOverDay}
        isOutsideRange={(day) => ( datePicker?.startDate &&
            (datePickerInput === END_DATE && (day.isBefore(datePicker?.startDate) || day.isAfter(datePicker?.startDate.clone().add(21, 'days'))))
            || (datePickerInput === START_DATE && (!isInclusivelyAfterDay(day, moment()) || isInclusivelyAfterDay(day, moment().add(18, 'months'))))
        )}
        showClearDates={true}
        //showDefaultInputIcon={true}
        startDatePlaceholderText="Arrival"
        // solid background close icon
        // withFullScreenPortal={props.withFullScreenPortal}
        // masked background - clickaway
        // withPortal={props.withPortal}
        isDayBlocked={checkDay}
        noBorder={true}
        readOnly={true}
      // left here to remind me its something we might need if we go back to portal mode
      // appendToBody={true}
        transitionDuration={0}
      />
    </Wrapper>
  )
}

FilterDate.propTypes = {
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  withFullScreenPortal: PropTypes.bool,
  monthFormat: PropTypes.string,
}

export default FilterDate
