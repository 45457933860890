import React from "react"
import { useState } from "react"
import styled from "styled-components"
import Filters from "./filters"
import SortSelector from "./sortSelector"
import SearchMap from "./searchMap"
import SearchFull from "./searchFull"
import AutoMobileDialog from "../../utils/autoMobileDialog"

//results
const ViewProperties = styled.div.attrs({
  className: "viewProperties",
})`
  grid-area: viewProperties;

  a{
    ${props => props.theme.buttons.primary};
    width:100%;
    text-align:center;
  }
`
const ResultsWrapper = styled.div.attrs({
  className: "resultsWrapper",
})`
  grid-area: results;
  padding-bottom: 1.5rem;
`

const Header = styled.div.attrs({
  className: "resultsHeader",
})`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`

const Count = styled.div.attrs({
  className: "count",
})`
  grid-area: count;
  margin-bottom: 2rem;
`

const LayoutToggle = styled.div`
  ${props => props.theme.formElements.select};
  width:fit-content;
  min-width:fit-content;
  ${props => props.theme.fonts.smallerFontSize};
  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    border-right: 1px solid ${props => props.theme.colors.lightGrey};

    &:nth-of-type(2n){
      display:none;
      @media ${props => props.theme.mq.lg} {
        display:inline-block;
      }
    }

    &:last-of-type{
      border:0;
    }

    @media ${props => props.theme.mq.lg} {
      &:first-of-type{
        display:inline-block;
      }
    }

  }
  button.active {
    color: ${props => props.theme.colors.secondary};
  }
`

const FilterWrapper = styled.div.attrs({
  className: "filterWrapper",
})`
  grid-area: filters;
`

//other
const Wrapper = styled.div`
  z-index: 2;
  margin: 0 auto;

  .DateRangePicker{
    width:100%;
  }

    /* With results */
    &.results-true {
      
      display: grid;
      grid-gap: 0;
      grid-template-rows: auto auto auto;
      grid-template-columns: auto;
      grid-template-areas:
        "count"
        "filters"
        "results";
      @media ${(props) => props.theme.mq.md} {
        grid-gap: 1.5rem;
        grid-template-rows: auto auto;
        grid-template-columns: 343px 1fr;
        grid-template-areas:
          "count count"
          "filters results";
      }
    }

    /* no results */
    &.results-false {
      ${(props) => props.theme.wrappersContainers.primaryWrapper};
      position: absolute;
        left:0;
        right:0;
        top: 70%;
        transform: translateY(-50%);

      @media ${(props) => props.theme.mq.xs} {
        position: absolute;
        left:0;
        right:0;
        top: 50%;
        transform: translateY(-50%);
      }

      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      grid-template-areas: "filters";

      @media ${(props) => props.theme.mq.xs} {
        grid-template-columns: 343px;
      }
    }
  `

// Build our search query (view graph schema for more specific query formatting)
// needs to be abstracted out of this file??
const searchQueryBuilder = (filters) => {
  var searchquery = {}

  if (filters?.where?.id) {
    searchquery.where = {}
    searchquery.where.location = filters.where.id?.toString();
  }
  if (filters?.who_adults) {
    searchquery.who = {}
    searchquery.who.adults = filters.who_adults
  }
  if (filters?.who_children) {
    if (searchquery.who === undefined) searchquery.who = {}
    searchquery.who.children = filters.who_children
  }
  if (filters?.who_infants) {
    if (searchquery.who === undefined) searchquery.who = {}
    searchquery.who.infants = filters.who_infants
  }
  if (filters?.who_pets) {
    if (searchquery.who === undefined) searchquery.who = {}
    searchquery.who.pets = filters.who_pets
  }
  if (filters?.with_price) {
    searchquery.with = {}
    searchquery.with.minPrice = filters.with_price.min
    searchquery.with.maxPrice = filters.with_price.max
  }
  if (filters?.with_bedrooms) {
    if (searchquery.with === undefined) searchquery.with = {}
    searchquery.with.beds = filters.with_bedrooms
  }
  if (filters?.with_bathrooms) {
    if (searchquery.with === undefined) searchquery.with = {}
    searchquery.with.baths = filters.with_bathrooms
  }
  if (filters?.when_from) {
    searchquery.when = {}
    searchquery.when.from = filters.when_from
    if (filters.when_to) {
      searchquery.when.to = filters.when_to
    }
    if (filters.when_variance) {
      searchquery.when.variance = filters.when_variance
    }
  }
  if (filters?.order) {
    searchquery.order = filters.order
  }
  if (filters?.what) {
    searchquery.what = {}

    filters.what.forEach(attribute => {
      if (attribute.value === true && attribute.id)
        searchquery.what[attribute.id] = true;
    })
  }
  if (filters?.deal) {
    searchquery.deal = filters.deal
  }
  
  return searchquery;
}

const Search = ({ showResults, filters, properties }) => {
  //Init filters state
  const [filterState] = useState(
    //note this function only gets called once on init
    function getStoredComponentState() {
      if (filters) {
        return filters
      }
      const storedState =
        typeof sessionStorage !== `undefined` && sessionStorage.filterState
      let storedFilter = storedState ? JSON.parse(storedState) : undefined
      //clean up old dev state
      if (storedFilter) {
        if (
          storedFilter.who_adults &&
          typeof storedFilter.who_adults !== "number"
        ) {
          storedFilter.who_adults = parseInt(storedFilter.who_adults)
        }
        if (
          storedFilter.who_children &&
          typeof storedFilter.who_children !== "number"
        ) {
          storedFilter.who_children = parseInt(storedFilter.who_adults)
        }
        if (
          storedFilter.who_infants &&
          typeof storedFilter.who_infants !== "number"
        ) {
          storedFilter.who_infants = parseInt(storedFilter.who_infants)
        }
        if (
          storedFilter.who_pets &&
          typeof storedFilter.who_pets !== "number"
        ) {
          storedFilter.who_pets = parseInt(storedFilter.who_pets)
        }
        if (
          storedFilter.with_bathrooms &&
          typeof storedFilter.with_bathrooms !== "number"
        ) {
          storedFilter.with_bathrooms = parseInt(storedFilter.with_bathrooms)
        }
        if (
          storedFilter.with_bedrooms &&
          typeof storedFilter.with_bedrooms !== "number"
        ) {
          storedFilter.with_bedrooms = parseInt(storedFilter.with_bedrooms)
        }
        if (storedFilter.what && !Array.isArray(storedFilter.what)) {
          storedFilter.what = null
        }
        if (storedFilter.with_price) {
          if (!storedFilter.with_price.min) {
            storedFilter.with_price = null
          }
        }
        return storedFilter
      } else{
        return {
          who_adults: 2,
          who_children: 0,
          who_infants: 0,
          who_pets: 0,
        }
      }
    }
  )

  function filterChange(filterElements,currentFilter) {
    // if a filter has been defined do not update saved state
    if (!filters) {
      const storedState =
        typeof sessionStorage !== `undefined` && sessionStorage.filterState

      const storedFilter = storedState ? JSON.parse(storedState) : {}

      const updatedFilter = { ...storedFilter, ...filterElements }
      typeof sessionStorage !== `undefined` && sessionStorage.setItem("filterState", JSON.stringify(updatedFilter))
    }
    // update the local state used for the real searches
    setsearchFilter({
      ...searchFilter,
      ...filterElements,
    });
  }

  //sort filter
  function sortResults(value) {
    setsearchFilter({
      ...searchFilter,
      order: value,
    });
  }

  const [searchFilter, setsearchFilter] = useState(filterState);

  const [resultsLayout, setResultsLayout] = useState(showResults?"listView":"none");

  const [results, setResults] = useState();

  const propertiesCount = results?.totalCount ?? (results?.properties?.length ?? 0);
  const facets = results?.facets;

  const resultsLoaded = results =>{
    setResults(results);
  }

  const dialogBtnText = 
      `We have ${propertiesCount} properties for you`;

  return (
    <Wrapper
      className={showResults ? "results-true" : "results-false"}
    >
      {propertiesCount > 0  && showResults &&
        <Count>
          We have {propertiesCount} properties
          for you
        </Count>
      }
      <FilterWrapper>
        <AutoMobileDialog inhibit={!showResults} openText={dialogBtnText} closedText={'Filter'} >         
          <Filters
            title={'Refine'}
            concise={!showResults}
            filters={filterState}
            facets={facets}
            onChange={filterChange}
          >
            <ViewProperties>
              <a href="/properties" title="View properties" >
                View {propertiesCount}{" "} properties
              </a>
            </ViewProperties>
          </Filters>
        </AutoMobileDialog>
      </FilterWrapper>
      <ResultsWrapper>
        { resultsLayout!== "none" &&
          <Header>
            {resultsLayout !== "mapView" &&
              <SortSelector onSelect={sortResults} sortOrder={searchFilter?.order}> </SortSelector>   
            }   
            <LayoutToggle>
              <button
                className={resultsLayout === "listView" ? "active" : ""}
                onClick={e => setResultsLayout("listView")}
              >
                List
              </button>
              <button
                className={resultsLayout === "gridView" ? "active" : ""}
                onClick={e => setResultsLayout("gridView")}
              >
                Grid
              </button>
              <button
                className={resultsLayout === "mapView" ? "active" : ""}
                onClick={e => setResultsLayout("mapView")}
              >
                Map
              </button>
            </LayoutToggle>        
          </Header>
        }     
        { 
          resultsLayout !== "mapView" ? (
          <SearchFull
            searchQuery={searchQueryBuilder(searchFilter)}
            onLoaded = {resultsLoaded}
            resultsLayout = {resultsLayout}
          />
          ) : (
            <SearchMap 
              searchQuery={searchQueryBuilder(searchFilter)}
              onLoaded = {resultsLoaded}
            ></SearchMap>
          )
        }
      </ResultsWrapper>

    </Wrapper>
  )
}

export default Search
