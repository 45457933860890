import React from "react"
import styled from "styled-components"
import Icon from "../../utils/icon"

const SortWrapper = styled.div`
position: relative;
display: inline-block;
${props => props.theme.fonts.smallerFontSize};

.svgWrapper {
  pointer-events: none;
  position: absolute;
  top: 1.4rem;
  right: 1rem;
  fill:#757575;
}

.cover {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
  span {
    background-color: white;
    margin-left: 1rem;
  }
}
`

const Select = styled.select`
  ${props => props.theme.formElements.select};
`

const SortSelector = ({ onSelect, sortOrder }) => {

   //handle sort selection
   const sortNameConverter = (sort) => {
    if (sort) {
      var name = sort.sorton
      var direction = sort.direction.toUpperCase()

      return name + " " + direction;
    } else {
      return "Sort by";
    }
  }

  function handleClick(event) {
    const sortKeys = event.currentTarget.value.split(" ");
    const sort = {
      sorton: sortKeys[0],
      direction: sortKeys[1],
    }

    onSelect(sort);
  }

  return (
        <SortWrapper>
          {/* <div className="cover">
            <span>{sortNameConverter(order)}</span>
          </div> */}

          <Select onChange={handleClick} value={sortNameConverter(sortOrder) }>
            <option hidden>Sort by</option>
            {/* <option value="Name ASC" data-direction="asc">
              Name ASC
            </option>
            <option value="Name DESC" data-direction="desc">
              Name DESC
            </option> */}
            <option value="Sleeps ASC" data-direction="asc">
              Sleeps ASC
            </option>
            <option value="Sleeps DESC" data-direction="desc">
              Sleeps DESC
            </option>
            <option value="Price ASC" data-direction="asc">
              Price ASC
            </option>
            <option value="Price DESC" data-direction="desc">
              Price DESC
            </option>
          </Select>

          <Icon className="selectArrow" name="selectArrow" width="10px" />
        </SortWrapper>
  )
}

export default SortSelector