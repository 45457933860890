import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components"
import Icon from "./icon"
import { IconButton } from "@material-ui/core";



const MobileFilterOpen = styled.div.attrs({
  className: "mobileFilterButton",
})`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.75rem;
  text-align: center;
  background-color: white;
  z-index: 21;
  box-shadow: 0px -3px 6px #00000029;

  span {
    ${props => props.theme.buttons.primary};
    width: 100%;
    display: block;

    &.active {
      font-style: italic;
    }
  }

  @media ${props => props.theme.mq.sm} {
    padding: 1rem;
  }

  @media ${props => props.theme.mq.md} {
    display: none;
  }
`

const DlgHeader = styled.div.attrs({})`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AutoMobileDialog({ children, inhibit, openText, closedText }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!mobile || inhibit) return children;

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DlgHeader>
          <IconButton 
            aria-label="close" 
            onClick={handleClose}>

            <Icon name="close" height="15px"/>
          </IconButton>
        </DlgHeader>
        <MobileFilterOpen onClick={handleClose}>
            <span className='active'>
              {openText}
            </span>
        </MobileFilterOpen>
        {children}
      </Dialog>
        <MobileFilterOpen onClick={handleClickOpen}>
            <span>
              {closedText}
            </span>
        </MobileFilterOpen>
    </div>
  );
}
