import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Locations from "./filters/locations"
import Member from "./filters/member"
import PriceRange from "./filters/priceRange"
import NumberSelect from "../../utils/numberSelect"

import FilterDate from "../datepicker/datepicker"
import Attributes from "./filters/attributes"
import PopDown from "../../utils/popDown"

import filterHelpers from "../../utils/filterHelpers"

const Wrapper = styled.div`
  margin: 0 auto;

  .DateRangePicker{
    width:100%;
  }

    /* Full search layout */
    &.concise-false {
      .filtersWrapper {
        .title {
          margin: 1rem 0 0 0;
          @media ${(props) => props.theme.mq.md} {
            margin: 0rem 0 0 0;
          }
        }

        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-bottom: 110px;

        @media ${(props) => props.theme.mq.sm} {
          padding-left: 1rem;
          padding-right: 1rem;
        }
        @media ${(props) => props.theme.mq.md} {
          padding-left: 0;
          padding-right: 0.3rem;
        }

        .singleFilter {
          margin-bottom: 0.5rem;
        }

        .inner::-webkit-scrollbar {
          width: 0.3rem;
          height: 0.3rem;
        }
        .inner::-webkit-scrollbar-button {
          background: white;
        }
        .inner::-webkit-scrollbar-track {
          background-color: white;
        }
        .inner::-webkit-scrollbar-track-piece {
          background: white;
        }
        .inner::-webkit-scrollbar-thumb {
          background-color: ${(props) => props.theme.colors.mediumGrey};
        }

        .whenWrapper {
          .DateRangePickerInput {
            ${(props) => props.theme.formElements.select};
          }
        }

        .viewProperties{
          display: none;
        }

        .resetWrapper {
          display: flex;
          justify-content: center;
          margin: 2rem 0;
            button{
              ${(props) => props.theme.buttons.secondary};
            }
          }
        }
      }
    }

    /* Concise search layout */
    &.concise-true {

      .filtersWrapper {
        display: grid;
        grid-gap: 0.5rem 0;
        grid-template-rows: repeat(4, auto);
        grid-template-columns: 1fr;
        grid-template-areas:
          "where"
          "when"
          "who"
          "viewProperties";

        h3,
        h4 {
          display: none;
        }

        .whereWrapper {
          .search {
            .faker {
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: white;
            }

            .searchBox {
              ${(props) => props.theme.formElements.input};
            }
          }

          .options,
          .noOptions {
            background-color: white;
            position: absolute;
            width: 100%;
            z-index: 1;
            padding: 0.5rem 0 1rem 0;
            margin: 0;

            
            .svgWrapper {
              height: 20px;
              width: 20px;
              position: relative;
              right: 0;
              top: 2px;
              left: -0.8rem;
                fill: ${(props) => props.theme.colors.primary};
            }

            li {
              display: flex;
            }

            button, a {
              background-color: transparent;
              border: 0;
              position: relative;
              padding: 0.5rem 0.5rem 0.5rem 1.5rem;
              display: flex;
              width: 100%;
              text-decoration: none;
              cursor: pointer;
              color: ${(props) => props.theme.colors.primaryFontColor};

              &:hover,
              &.option-active {
                background-color: ${(props) => props.theme.colors.lightGrey};
              }
            }

            button{
              .svgWrapper {
                top: -1px;
              }
            }


          }
        }

        .whenWrapper {
          .DateRangePickerInput {
            ${(props) => props.theme.formElements.select};
          }
        }

        .resetWrapper,
        .withWrapper,
        .whatWrapper,
        .count {
          display: none;
        }
      }
    }
  `

const FiltersWrapper = styled.div.attrs({
  className: "filtersWrapper",
})`

`

const WhereWrapper = styled.div`
  grid-area: where;
  h4 {
    font-style: italic;
    margin-top: 1.2rem;
    margin-bottom: 0.25rem;
    color: ${props => props.theme.colors.primary};
    ${props => props.theme.fonts.standardFontSize};
  }
`

const WhenWrapper = styled.div.attrs({
  className: "whenWrapper",
})`
  grid-area: when;
`

const WhoWrapper = styled.div`
  &.concise{
    h3,
    h4 {
      display: none;
    }
 
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
    width: 100%;
    background-color: white;

`

const WithWrapper = styled.div.attrs({
  className: "withWrapper",
})`
  grid-area: with;
  background-color: white;
  position: relative;
  .withDisplayArrow {
    pointer-events: none;
    position: absolute;
    top: 0.85rem;
    right: 0.5rem;
  }

  h4 {
    font-style: italic;
    margin-top: 1.5rem;
    margin-bottom: 0.25rem;
    color: ${props => props.theme.colors.primary};
    ${props => props.theme.fonts.standardFontSize};
  }
`

const WhatWrapper = styled.div.attrs({
  className: "whatWrapper",
})`
  grid-area: what;
  background-color: white;
  margin-bottom: 1rem;

  h4 {
    font-style: italic;
    margin-top: 1.5rem;
    margin-bottom: 0.25rem;
    color: ${props => props.theme.colors.primary};
    ${props => props.theme.fonts.standardFontSize};
  }
`

//=========================================================
//
//
const Filters = ({ title, children, concise, filters, facets, onChange }) => {

  //static query
  const filterData = useStaticQuery(
    graphql`
      query {
        allWpProperty {
          edges {
            node {
              title
              link
            }
          }
        }
        bookingGraph {
          getPropertyLocations {
            id
            name
          }
        }
      }
    `
  )

  //filters state
  const [propertyFilterState, setPropertyFilterState] = useState(
    //note this function only gets called once on init
    filterHelpers.getFilterState(filters, filterHelpers.initialFilterState)
  )

  // map the facets to attributes
  // composite attributes filter
  // all attributes returned from the query with the values from the attributes stored state
  const attributesFilter = facets?.map(facet => ({
    id: facet.id,
    title: facet.title,
    count: facet.count,
    value: getAttribute(facet.id)?.value
  })
  );

  function getAttribute(attr) {
    return propertyFilterState?.what?.filter(item => item.id === attr)[0];
  }

  // ==========================================
  // filter change events 

  //what filter
  function whatFilter(attribute) {
    const attributes = propertyFilterState?.what ?? [];
    //find it and remove it
    var newAttributes = attributes.filter(attr => !(attr.id === attribute.id));
    // if checked
    if (attribute.value) {
      newAttributes.push(attribute);
    }
    updateFilter({ what: newAttributes, });
  }

  //where filter
  function whereFilter(value) {
    // temp fix - object should have is property field ??
    if (!value || !value.url) {
      updateFilter({ where: value, });
    } else {
      console.log("property selected used link instead");
    }
  }

  //when filter
  function whenFilter(startDate, endDate) {
    updateFilter({
      when_from: startDate,
      when_to: endDate,
      when_variance: propertyFilterState?.when?.variance ? propertyFilterState.when.variance : 3,
    });
  }

  //flexibility filter
  function flexFilter(value) {
    updateFilter({ when_variance: value, });
  }

  //adults filter
  function adultsFilter(value) {
    updateFilter({ who_adults: value, });
  }

  //children filter
  function childrenFilter(value) {
    updateFilter({ who_children: value, });
  }

  //infants filter
  function infantsFilter(value) {
    updateFilter({ who_infants: value, });
  }

  //pets filter
  function petsFilter(value) {
    updateFilter({ who_pets: value, });
  }

  //priceRange filter
  function priceRangeFilter(value) {
    updateFilter({ with_price: value, });
  }

  //bedrooms filter
  function bedroomsFilter(value) {
    updateFilter({ with_bedrooms: value, });
  }

  //bathrooms filter
  function bathroomsFilter(value) {
    updateFilter({ with_bathrooms: value, });
  }

  function resetState() {
    console.log("reset");
    updateFilter(filterHelpers.initialFilterState);
  }

  // all changes come through here
  function updateFilter(value) {
    const newFilter = {
      ...propertyFilterState,
      ...value,
      page: 1,
    };

    setPropertyFilterState(newFilter)
    // notify parent of change
    if (onChange) {
      onChange(value, newFilter);
    }
  }

  //==============================================
  // who control
  // use the npm module ??
  function singularise(plural, count) {
    if (count === 1) {
      if (plural.toLowerCase().endsWith("ren")) {
        const x = plural.slice(0, -3)
        return x;
      }
      if (plural.endsWith('s')) {
        return plural.slice(0, -1);
      }
    } else {
      return plural
    }
  }

  function whoCountText(typeName, value) {
    return value + " " + singularise(typeName, value);
  }

  function whoCountSummary() {
    return (
      whoCountText("Adults", propertyFilterState.who_adults) + ', ' +
      whoCountText("Children", propertyFilterState.who_children) + ', ' +
      whoCountText("Infants", propertyFilterState.who_infants) + ', ' +
      whoCountText("Pets", propertyFilterState.who_pets)
    )
  }

  //Create a composite list of locations (and properties if concise)
  function locationList() {
    var propertyList = filterData.allWpProperty.edges

    var locations = filterData.bookingGraph.getPropertyLocations
      .map(location => ({ title: location.name, id: location.id }))
      .sort((a, b) => -b.title.localeCompare(a.title));

    locations.unshift({ title: "All Locations" });

    if (concise) {
      var properties = propertyList
        .map((property, ndx) => ({ title: property.node.title, id: ndx, url: new URL(property.node.link).pathname }))
        .sort((a, b) => -b.title.localeCompare(a.title));

      locations = locations.concat(properties);
    }
    return locations;
  }

  return (
    <Wrapper
      className={concise ? "concise-true" : "concise-false"}
    >
      <FiltersWrapper>
        <h3 className="title">{title}</h3>

        <WhereWrapper>
          <h4>Where and when</h4>
          <Locations
            onSelect={whereFilter}
            showResults={!concise}
            value={propertyFilterState.where}
            locations={locationList()}
            variant={"outlined"}
          />
        </WhereWrapper>

        <WhenWrapper>
          <FilterDate
            from={propertyFilterState.when_from}
            to={propertyFilterState.when_to}
            //removed for now as these dont play nicely with the MUI dialogue in mobile
            //withFullScreenPortal={!concise}
            //withPortal={!concise}
            onChildClick={whenFilter}
          ></FilterDate>

          {propertyFilterState.when_from &&
            <NumberSelect
              title="days flexibility"
              onClick={flexFilter}
              concise={false}
              value={propertyFilterState.when_variance}
              range={{ min: 0, max: 8, }}
            />
          }
        </WhenWrapper>

        <PopDown
          inhibit={!concise}
          label={whoCountSummary()}
        >
          <WhoWrapper
            className={concise ? "concise" : ""}>
            <h4>Guests</h4>

            <div >
              <Member
                title="Adults"
                onClick={adultsFilter}
                concise={concise}
                value={propertyFilterState.who_adults}
                range={{ min: 1, max: 20, }}
              />
              <Member
                title="Children"
                onClick={childrenFilter}
                concise={concise}
                value={propertyFilterState.who_children}
                range={{ min: 0, max: 8, }}
                // itemRender = {whoCountChildren}
                ageRange="3-16"
              />
              <Member
                title="Infants"
                onClick={infantsFilter}
                concise={concise}
                value={propertyFilterState.who_infants}
                range={{ min: 0, max: 8, }}
                ageRange="0-2"
              />
              <Member
                title="Pets"
                onClick={petsFilter}
                concise={concise}
                value={propertyFilterState.who_pets}
                range={{ min: 0, max: 8, }}
              />
            </div>
          </WhoWrapper>

        </PopDown>

        <WithWrapper>
          <h4>Price Range</h4>

          <div className="withDisplay">
            <PriceRange
              title="Price Range"
              onClick={priceRangeFilter}
              concise={concise}
              value={propertyFilterState.with_price}
              range={{ min: 0, max: 2000, increment: 200, }}
            />
          </div>

          <h4>Rooms</h4>

          <div className="withDisplay">
            <NumberSelect
              title="Bedrooms"
              onClick={bedroomsFilter}
              concise={concise}
              value={propertyFilterState.with_bedrooms}
              range={{ min: 1, max: 8, }}
            />

            <NumberSelect
              title="Bathrooms"
              onClick={bathroomsFilter}
              concise={concise}
              value={propertyFilterState.with_bathrooms}
              range={{ min: 1, max: 8, }}
            />
          </div>
        </WithWrapper>

        <WhatWrapper>
          <h4>Features</h4>
          <Attributes
            showEmptyFacets={true}
            onChange={whatFilter}
            attributes={attributesFilter}
          />
        </WhatWrapper>

        <div className="resetWrapper">
          <button onClick={resetState}>
            Reset your search
              </button>
        </div>

        {children}

      </FiltersWrapper>
    </Wrapper>
  )
}

export default Filters
