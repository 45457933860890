import React from "react"
import styled from "styled-components"
import CheckboxComponent from "./checkbox"

const Wrapper = styled.div.attrs({
  className: "whatWrapper",
})`
  grid-area: what;
  margin-bottom: 1rem;

  .whatDisplay {
    div {
      margin-bottom: 0.5rem;
    }
  }
`
//initial filter state variables
export const defaultAttributes = [
  { id: "wifi", title: "WiFi", value: false },
  { id: "garden", title: "Garden", value: false },
  { id: "patio", title: "Patio", value: false },
  { id: "parking", title: "Parking", value: false },
  { id: "woodburner", title: "Woodburner", value: false },
  { id: "openfire", title: "Open Fire", value: false },
  { id: "seaview", title: "Sea View", value: false },
  { id: "carpoint", title: "Car Point", value: false },
  { id: "balcony", title: "Balcony", value: false },
  { id: "lift", title: "Lift", value: false },
  { id: "tv", title: "TV", value: false },
  { id: "dishwasher", title: "Dishwasher", value: false },
  { id: "washingmachine", title: "Washing machine", value: false },
  { id: "heating", title: "Heating", value: false },
  { id: "garage", title: "Garage", value: false },
  { id: "hairdryer", title: "Hairdryer", value: false },
  { id: "bathtowels", title: "Bath towels", value: false },
  { id: "bedlinen", title: "Bed linen", value: false },
  { id: "bbq", title: "Barbeque", value: false },
  { id: "aircon", title: "Aircon", value: false },
  { id: "hottub", title: "Hot tub", value: false },
  { id: "roofterrace", title: "Roof terrace", value: false },
  { id: "towncentre", title: "Town centre", value: false },
  { id: "countryside", title: "Countryside", value: false },
  { id: "skytv", title: "Sky tv", value: false },
  { id: "showerroom", title: "Shower room", value: false },
  { id: "kingbed", title: "Kingsize bed", value: false },
  { id: "walkbeach", title: "Walk beach", value: false },
  { id: "walkpub", title: "Walk pub", value: false },
  { id: "romantic", title: "Romantic", value: false },
  { id: "family", title: "Family", value: false },
  { id: "hiddengem", title: "Hidden gem", value: false },
  { id: "beachchalet", title: "Beach chalet", value: false },
  { id: "large", title: "Large", value: false },
  { id: "countrywalks", title: "Country walks", value: false },
  { id: "luxury", title: "Luxury", value: false },
  { id: "peaceful", title: "Peaceful", value: false },
]

const Attributes = React.memo(
  ({ showEmptyFacets, onChange, attributes, resetAll }) => {
    function onChecked(event) {
      const { value, checked } = event.target

      const attr = attributes?.filter((attr) => attr.id === value)[0]

      var newAtrr = { ...attr, value: checked }
      onChange(newAtrr)
    }

    const filteredAttributes = showEmptyFacets
      ? attributes?.filter(
        (attr) => attr.count > 0 && !attr.readOnly && !attr.hidden
      )
      : attributes

    return (
      <Wrapper>
        <div className="whatDisplay">
          {filteredAttributes?.map((item) => (
            <CheckboxComponent
              key={item.id.toString()}
              title={item.title}
              whatPart={item.id}
              checked={item?.value || false}
              displayCount={item?.count || 0}
              onChildClick={onChecked}
            />
          ))}
        </div>
      </Wrapper>
    )
  }
)

export default Attributes
