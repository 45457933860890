import React from "react"
import styled from "styled-components"
import { gql, useQuery } from "@apollo/client"
import Loader from "../loadingAnim/loader"
import ResultsList from "./resultsList"

const Loading = styled.div`
  grid-area: results;

  .inner {
    position: relative;
    z-index: 1;
  }

  .errorMsg {
    text-align: center;
  }
`

// This query is executed at run time by Apollo.
const APOLLO_QUERY = gql`
query getMyProperties($searchquery: SearchQuery) {
  getProperties(query: $searchquery) {
    totalCount
    properties {
      propRef: ref
      name
      wpurl
      rating
      prices {
        priceBase
      }
      description {
        short
      }
      sleeps
      bedrooms
      bathrooms
      pets
      where: address {
        town
        coords {
          lat
          lon
        }
      }
      attributes{
        category
        name
        value
      }
      images {
        description
        url
      }
      gallery {
        description
        url
      }
      offers {
        startDate
        endDate
        nights
        discountPercentage
        condition
      }
    }
    facets {
      title
      id
      name
      count
    }
  }
}
`

const PAGE_SIZE = 24

const SearchFull = ({ searchQuery, onLoaded, resultsLayout }) => {

  // Our Apollo Client query that does the heavy lifting to fetch our data and returns data to loadDynamic handler
  const { loading, error, data, fetchMore } = useQuery(APOLLO_QUERY, {
    variables: {
      searchquery: {
        ...searchQuery,
        pages: { cursor: 1, size: PAGE_SIZE },
      }
    },
    ssr: false,
    onCompleted: data => {
      // console.log("SearchFull loaded");
      if (onLoaded) {
        onLoaded(data?.getProperties);
      }
    }
  })

  const propertiesResults = data?.getProperties?.properties;
  const propertiesCount = data?.getProperties?.totalCount ?? (propertiesResults?.length ?? 0);
  //const facets = data?.getProperties?.facets;

  //load more
  const loadMore = () => {
    const currentSize = propertiesResults?.length;
    const page = Math.floor(currentSize / PAGE_SIZE) + 1;
    fetchMore({
      variables: {
        searchquery: {
          ...searchQuery,
          pages: { cursor: page, size: PAGE_SIZE }
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...fetchMoreResult,
          getProperties: {
            ...fetchMoreResult.getProperties,
            properties: [
              ...prev.getProperties.properties,
              ...fetchMoreResult.getProperties.properties,
            ],
          },
        };
      },
    })
  }
  //=================================
  // render functions

  if (resultsLayout === "none")
    return null;

  return (

    <>
      <Loading>
        {loading &&
          <>
            <div className="inner">
              <Loader />
            </div>
            <div className="errorMsg">
              Searching for your next holiday property
                </div>
          </>
        }
        {error &&
          <p>Error: ${error.message}</p>
        }
        {!loading && !error && !propertiesResults.length &&
          <div className="errorMsg">
            Sorry there are no properties available for your search criteria
                    <br />
            <br />
          </div>
        }
      </Loading>
      {propertiesResults?.length > 0 &&
        <ResultsList
          properties={propertiesResults}
          onMoreClick={loadMore}
          resultsCount={propertiesCount}
          propertiesLayout={resultsLayout}
        />
      }
    </>

  )
}

export default SearchFull
