import React, { useState } from "react"
import styled from "styled-components"
import Icon from "./icon"

const WrapperTwo = styled.div`
display: flex;
align-items:center;
justify-content: space-between;
padding: 0.5rem;

.numeric {
    display: flex;
    align-items:center;
    height: 32px;
    span {
        width: 44px;
        text-align:center;
    }

    button {
		border: 0;
	 	padding: 0;
        fill: ${props => props.theme.colors.primary};
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        overflow: hidden;
		height:100%;
        &:hover {
        	fill: ${props => props.theme.colors.secondary};
		}
		&:disabled {
			opacity: 0.2;
			&:hover {
			  fill: ${props => props.theme.colors.primary};
			}
		}	  
    }
}
`

const NumericInput = ({ title, value, onChange, range }) => {

  //State
  // const [valueState] = useState(
  //   {
  //     min: range?.min ?? 0,
  //     max: range?.max ?? Number.MAX_SAFE_INTEGER,
  //   }
  // )

  const valMin = range?.min ?? Number.MIN_SAFE_INTEGER
  const valMax = range?.max ?? Number.MAX_SAFE_INTEGER

  if (value>valMax) sendUp(valMax)
  if (value<valMin) sendUp(valMin)

  function intValue() {
    return value !== undefined ? value : valMin;
  }

	//disable clicks
	function max() {
		return intValue() >= valMax;
	}
	function min() {
		return intValue() <= valMin;
	}

	//increment
	function IncrementItem() {
		var num = intValue() + 1;

		sendUp(num);
	}

	//decrement
	function DecreaseItem() {
		var num = intValue() - 1

		sendUp(num);
	}

	function sendUp(val) {
		if (onChange) {
			onChange(Math.min(val, range.max));
		}
	}

    return (
        <WrapperTwo>
            <span className="title">{title}</span>
            <span className="numeric">
                <button onClick={DecreaseItem} disabled={min()} type="button" >
                    <Icon name="minus" height="2em" fill="white" />
                </button>
                <span>{intValue()}</span>
                <button onClick={IncrementItem} disabled={max()} type="button" >
                    <Icon name="plus" height="2em" fill="white" />
                </button>
            </span>
        </WrapperTwo>
    )
}

export default NumericInput